<template>
  <div style="height: 100%;">
    <div class="div-main-list-page" v-if="view === ORDER_VIEW.MAIN_ORDER">
      <div class="div-main-list-page-title">
        <h1>{{orderType === ORDER_TYPE.DELIVERED ? 'Delivered' : (orderType === ORDER_TYPE.EX_WHARF ? 'Ex-Wharf' : 'Barging Nomination')}}</h1>
      </div>
      <div class="flex-between-row div-main-list-page-tab-search-btn">
        <!-- <div> -->
          <el-tabs class="tab-bar" v-model="orderTabActive" @tab-click="handleGetOrders(orderPagination.pageSize, 0)" >
            <el-tab-pane label="All" name="All"/>
            <el-tab-pane label="Draft" name="Draft"/>
            <el-tab-pane label="Open Enquiry" name="Open Enquiry"/>
            <el-tab-pane label="Pending Credit Authorization" name="Pending Credit Authorization"/>
            <el-tab-pane label="Credit Authorization Rejected" name="Credit Authorization Rejected"/>
            <el-tab-pane label="Confirmed" name="Confirmed"/>
            <el-tab-pane label="Verified" name="Verified"/>
            <el-tab-pane label="Archive" name="Archive"/>
          </el-tabs>
        <!-- </div> -->
        <div class="flex-end">
          <div class="search-bar">
          <el-input
            v-model="orderSearch" class="round-input"
            :placeholder="`Search Order No. ${orderType === ORDER_TYPE.EX_WHARF ? 'or Terminal' : 'or Vessel'}`" @keypress.enter.native="handleGetOrders(orderPagination.pageSize, 0, true)"
          >
            <el-button type="primary" slot="append" icon="el-icon-search" @click="handleGetOrders(orderPagination.pageSize, 0, true)"/>
          </el-input>
          </div>
          <div style="padding-top:5px" v-if="hasPermission($permissionFunctionCode.ADD)">
            <el-dropdown class="primary-button" trigger="click" @command="handleCreateOrderSelect">
              <span>
                Create Order<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="ORDER_TRADE_TYPE.PURCHASE">Purchase Order</el-dropdown-item>
                <el-dropdown-item :command="ORDER_TRADE_TYPE.SALES">Sales Order</el-dropdown-item>
                <el-dropdown-item :command="ORDER_TRADE_TYPE.PURCHASE_AND_SALES" v-if="orderType !== ORDER_TYPE.EX_WHARF">Purchase & Sales Order</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>

      </div>
      <table-order hasPagination hasTableMenu :isOrderTableLoading="isOrderTableLoading" :orderTableData="orderTableData" :orderPagination="orderPagination" :isAllVerified="orderTabActive === 'Verified' || isAllVerified"
        :orderType="orderType" @handleOrderTableMenuClick="handleOrderTableMenuClick" @handleOrderPageChange="handleOrderPageChange" @handleOrderPageSizeChange="handleOrderPageSizeChange"
      />
    </div>
    <order-form v-if="view !== ORDER_VIEW.MAIN_ORDER" :propsView="view" :orderType="orderType" :propsTradeType="tradeType" :orderId="orderId" :isAllVerified="orderTabActive === 'Verified' || isAllVerified"
      isOrderSideBar @handleCloseOrderForm="handleCloseOrderForm" @handleOrderViewChange="handleOrderViewChange" @handleOrderIdChange="handleOrderIdChange" @handleIsAllVerifiedChange="handleIsAllVerifiedChange"
    />
    <dialog-link-order :isUnlink="isUnlink" :orderType="ORDER_TYPE.DELIVERED" :order="orderSelected" :dialogLinkOrder="dialogLinkOrder"
      @handleLinkOrderDialogClose="dialogLinkOrder = false" @handleConfirmLinkOrderClick="handleConfirmLinkOrderClick"
    />
    <dialog-link-jobs :isUnlink="isUnlink" :dialogLinkJobs="dialogLinkJobs" :orderId="orderId" :order="orderSelected"
      @handleLinkJobsDialogClose="dialogLinkJobs = false"
    />
    <dialog-attachment :order="orderSelected" :dialogAttachment="dialogAttachment" @handleAttachmentDialogClose="dialogAttachment = false" />
    <dialog-blockchain-info @onClose="handleCloseBlockchainInfo" v-if="blockchainInfoVisible" :propsData="blockchainData"/>
  </div>
</template>
<script>
import {mapState} from 'vuex';
import OrderForm from './components/OrderForm'
import { ORDER_STATUS, ORDER_VIEW, ORDER_TYPE, ORDER_TRADE_TYPE } from '@/constants.js'
import TableOrder from './components/TableOrder'
import DialogLinkOrder from './components/DialogLinkOrder'
import DialogLinkJobs from './components/DialogLinkJobs'
import DialogAttachment from './components/DialogAttachment'
import DialogBlockchainInfo from '@/components/DialogBlockchainInfo'
import { getOrderById, getOrders, linkUnlinkOrder } from '@/services/modules/order.js'

export default {
  name: 'Order',
  components: {
    OrderForm,
    TableOrder,
    DialogLinkOrder,
    DialogLinkJobs,
    DialogAttachment,
    DialogBlockchainInfo
  },
  data () {
    const orderPagination = {
      pageSize: 50,
      pageNumber: 1,
      total: 0
    }
    return {
      ORDER_TYPE,
      ORDER_TRADE_TYPE,
      ORDER_VIEW,
      ORDER_STATUS,
      orderTableData: [],
      view: ORDER_VIEW.MAIN_ORDER,
      orderType: null,
      tradeType: null,
      orderSearch: null,
      orderSelected: null,
      orderId: null,
      isOrderTableLoading: true,
      orderTabActive: 'All',
      orderPagination,
      dialogLinkOrder: false,
      dialogLinkJobs: false,
      dialogAttachment: false,
      isUnlink: false,
      blockchainInfoVisible: false,
      blockchainData: null,
      isAllVerified: false
    }
  },
  created () {
    this.getInitialView()

  },
  computed: {
    ...mapState(['currentCompany', "currentCompanySettings"]),
    myCompanyId () {
      return this.$store.state.currentCompany.id
    }

  },
  watch: {
    $route () {
      this.isOrderTableLoading = true
      this.getInitialView()
    },
    'currentCompany.id': {
      handler (value) {
        if (value) {
          setTimeout(() => {
            this.$store.dispatch('getCompanySettings', value)
          }, 500)
        }
      },
      immediate: true
    }
  },
  methods: {
    getInitialView () {
      this.view = ORDER_VIEW.MAIN_ORDER
      this.orderTabActive = 'All'
      this.orderSearch = null
      if (this.$router.currentRoute.name === 'MENU_ORDER_DELIVERED') {
        this.orderType = ORDER_TYPE.DELIVERED
      } else if (this.$router.currentRoute.name === 'MENU_ORDER_EX_WHARF') {
        this.orderType = ORDER_TYPE.EX_WHARF
      } else {
        this.orderType = ORDER_TYPE.BARGING_NOMINATION
      }
      this.handleGetOrders(50, 0)
    },
    handleOrderViewChange (view) {
      this.view = view
    },
    handleIsAllVerifiedChange (isAllVerified) {
      this.isAllVerified = isAllVerified
    },
    handleConfirmLinkOrderClick (upstreamOrder, isSalesOrder, isLink) {
      const upstreamOrderId = isSalesOrder ? upstreamOrder.id : this.orderSelected.id
      const downstreamOrderId = isSalesOrder ? this.orderSelected.id : upstreamOrder.id
      linkUnlinkOrder(downstreamOrderId, upstreamOrderId, isLink).then(res => {
        if (res?.code === 1000) {
          isLink ? this.$message.success('Order has been linked.') : this.$message.success('Order has been unlinked.')
          this.handleGetOrders(this.orderPagination.pageSize, this.orderPagination.pageNumber - 1)
          this.dialogLinkOrder = false
        }
      })
    },
    handleGetOrders (pageSize, pageNumber, isSearch) {
      this.isOrderTableLoading = true
      let query = `orderType:'${this.orderType}' ${this.getOrderFilterStatus() || ''}&sort=updatedAt desc`
      if (isSearch) {
        query = `orderType:'${this.orderType}'${`
         and ((salesOrderNo ~'*${this.orderSearch}*' and sellerSysOrganizationId:'${this.myCompanyId}')
          or (purchaseOrderNo ~'*${this.orderSearch}*' and buyerSysOrganizationId:'${this.myCompanyId}')
            or vesselName ~'*${this.orderSearch}*' or terminal ~'*${this.orderSearch}*')`}
            ${this.getOrderFilterStatus() || ''}&sort=updatedAt desc`
      }
      getOrders(pageSize, pageNumber, query).then(res => {
        if (res?.code === 1000) {
          this.orderTableData = res.data.content
          this.orderPagination.total = res.data.totalElements
          this.orderPagination.pageNumber = res.data.pageable.pageNumber + 1
        }
        this.isOrderTableLoading = false
      })
    },
    handleOrderPageChange (pageNumber) {
      this.handleGetOrders(this.orderPagination.pageSize, pageNumber - 1)
    },
    handleOrderPageSizeChange (pageSize) {
      this.handleGetOrders(pageSize, 0)
    },
    handleOrderIdChange (orderId) {
      this.orderId = orderId
    },
    handleOrderTableMenuClick (menuItem, orderId) {
      this.orderId = orderId
      if (menuItem === 'view') {
        this.view = ORDER_VIEW.VIEW_ORDER
      } else if (menuItem === 'viewBlockchainInfo') {
        this.blockchainData = {
          moduleApi: '/orders',
          id: orderId
        }
        this.blockchainInfoVisible = true
      } else {
        getOrderById(orderId).then(res => {
          if (res?.code === 1000) {
            this.orderSelected = res.data
            switch (menuItem) {
              case 'linkOrder':
                this.isUnlink = false
                this.dialogLinkOrder = true
                break
              case 'unlinkOrder':
                this.isUnlink = true
                this.dialogLinkOrder = true
                break
              case 'linkJobs':
                this.isUnlink = false
                this.dialogLinkJobs = true
                break
              case 'unlinkJobs':
                this.isUnlink = true
                this.dialogLinkJobs = true
                break
              default:
                this.dialogAttachment = true
            }
          }
        })
      }
    },
    handleCreateOrderSelect (tradeType) {
      this.tradeType = tradeType
      this.view = ORDER_VIEW.CREATE_ORDER
    },
    handleCloseOrderForm (action) {
      if (action !== 'CLOSE') {
        this.orderTabActive = 'All'
      }
      this.orderSearch = null
      this.view = ORDER_VIEW.MAIN_ORDER
      this.handleGetOrders(50, 0)
    },
    handleCloseBlockchainInfo () {
      this.blockchainInfoVisible = false
    },
    getOrderFilterStatus () {
      switch (this.orderTabActive) {
        case 'All':
          return `and (exists(products.status:'PENDING') or exists(otherCosts.status:'CONFIRMED')) and ((sellerOrderStatus !'${ORDER_STATUS.CANCELLED}' and sellerSysOrganizationId:'${this.myCompanyId}') or (buyerOrderStatus !'${ORDER_STATUS.CANCELLED}' and buyerSysOrganizationId:'${this.myCompanyId}'))`
        case 'Draft':
          return ` and (sellerOrderStatus: '${ORDER_STATUS.DRAFT}' or buyerOrderStatus: '${ORDER_STATUS.DRAFT}')`
        case 'Open Enquiry':
          return ` and ((sellerOrderStatus in ('${ORDER_STATUS.PENDING_SELLER_CONFIRMATION}', '${ORDER_STATUS.PENDING_SELLER_CANCELLATION}') and sellerSysOrganizationId:'${this.myCompanyId}') or ((buyerOrderStatus in ('${ORDER_STATUS.PENDING_BUYER_CONFIRMATION}', '${ORDER_STATUS.PENDING_BUYER_CANCELLATION}') and buyerSysOrganizationId:'${this.myCompanyId}')))`
        case 'Pending Credit Authorization':
          return ` and sellerOrderStatus: '${ORDER_STATUS.PENDING_CREDIT_AUTHORIZATION}' and sellerSysOrganizationId:'${this.myCompanyId}'`
        case 'Credit Authorization Rejected':
          return ` and sellerOrderStatus: '${ORDER_STATUS.CREDIT_AUTHORIZATION_REJECTED}' and sellerSysOrganizationId:'${this.myCompanyId}'`
        case 'Confirmed':
          return `and (exists(products.status:'PENDING') or exists(otherCosts.status:'CONFIRMED')) and ((sellerOrderStatus: '${ORDER_STATUS.CONFIRMED}' and sellerSysOrganizationId:'${this.myCompanyId}') or (buyerOrderStatus: '${ORDER_STATUS.CONFIRMED}' and buyerSysOrganizationId:'${this.myCompanyId}'))`
        case 'Verified':
          return ` and not (exists(products.status:'PENDING')) and not (exists(otherCosts.status:'CONFIRMED')) and ((sellerOrderStatus: '${ORDER_STATUS.CONFIRMED}' and sellerSysOrganizationId:'${this.myCompanyId}') or (buyerOrderStatus: '${ORDER_STATUS.CONFIRMED}' and buyerSysOrganizationId:'${this.myCompanyId}'))`
        case 'Archive':
          return ` and (sellerOrderStatus: '${ORDER_STATUS.CANCELLED}' or buyerOrderStatus: '${ORDER_STATUS.CANCELLED}')`
        default:
          return ''
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "index";
</style>
